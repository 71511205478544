import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {DatePickerModule} from '../custom-components/datepicker/datepicker.module';
import {LabelModule} from '../custom-components/ellipsis-label/label.module';
import {ProgressButtonModule} from '../custom-components/progress-button/progress-button.module';
import {TagModule} from '../custom-components/tag/tag.module';
import {TimePickerFormInputModule} from '../custom-components/time-picker-form-input/time-picker-form-input.module';
import {MomentDatePipeModule} from '../custom-pipes/moment-date-pipe.module';
import {TranslationModule} from '../translation/translation.module';
import {AutoFocusModule} from './../custom-directives/auto-focus/auto-focus.module';
import {InputL10nDecimalModule} from './../custom-directives/input-l10n-decimal/input-l10n-decimal.module';
import {CellButtonComponent} from './cell-button/cell-button.component';
import {CustomCellEditorComponent} from './custom-cell-editor/custom-cell-editor.component';
import {DisabledCellRendererComponent} from './disabled-cell-renderer/disabled-cell-renderer.component';
import {EmptyCellRendererComponent} from './empty-cell-renderer/empty-cell-renderer.component';
import {GridCheckedFlagComponent} from './grid-checked-flag/grid-checked-flag.component';
import {GridDateTimeComponent} from './grid-date-time/grid-date-time.component';
import {GridTooltipComponent} from './grid-tooltip/grid-tooltip.component';
import {CheckboxCellEditorComponent} from './input/checkbox-cell-editor/checkbox-cell-editor.component';
import {GridInputComponent} from './input/grid-input.component';
import {ProgressCellRendererComponent} from './input/progress-cell-renderer/progress-cell-renderer.component';
import {LinkIconRendererComponent} from './link-icon-renderer/link-icon-renderer.component';
import {NumberCellEditorComponent} from './number-cell-editor/number-cell-editor.component';
import {NumberCellRendererComponent} from './number-cell-renderer/number-cell-renderer.component';
import {LoadingCellOverlayComponent} from './overlay/loading-cell-overlay/loading-cell-overlay.component';
import {LoadingOverlayComponent} from './overlay/loading-overlay/loading-overlay.component';
import {NoDataOverlayComponent} from './overlay/no-data-overlay/no-data-overlay.component';
import {OverlayActionsService} from './overlay/overlay-actions.service';
import {OverlayComponent} from './overlay/overlay/overlay.component';
import {StringCellEditorComponent} from './string-cell-editor/string-cell-editor.component';
import {StringCountCellRendererComponent} from './string-count/string-count-cell-renderer.component';
import {SuffixIconCellRendererComponent} from './suffix-icon-cell-renderer/suffix-icon-cell-renderer.component';
import {GridTagComponent} from './tag/grid-tag.component';
import {TooltipCellRendererComponent} from './tooltip-cell-renderer/tooltip-cell-renderer.component';

@NgModule({
  imports: [
    LabelModule,
    CommonModule,
    TranslationModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonModule,
    DatePickerModule,
    AutoFocusModule,
    MomentDatePipeModule,
    TagModule,
    InputL10nDecimalModule,
    MatCheckboxModule,
    ProgressButtonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    TimePickerFormInputModule
  ],
  exports: [
    GridTagComponent,
    LoadingOverlayComponent,
    NoDataOverlayComponent,
    LoadingCellOverlayComponent,
    GridInputComponent,
    GridDateTimeComponent,
    NumberCellEditorComponent,
    CellButtonComponent,
    NumberCellRendererComponent,
    ProgressCellRendererComponent,
    StringCellEditorComponent,
    GridTooltipComponent,
    CheckboxCellEditorComponent,
    StringCountCellRendererComponent,
    EmptyCellRendererComponent,
    GridCheckedFlagComponent,
    OverlayComponent,
    DisabledCellRendererComponent,
    TooltipCellRendererComponent,
    LinkIconRendererComponent,
    CustomCellEditorComponent
  ],
  declarations: [
    GridTagComponent,
    LoadingOverlayComponent,
    NoDataOverlayComponent,
    LoadingCellOverlayComponent,
    GridInputComponent,
    GridDateTimeComponent,
    NumberCellEditorComponent,
    CellButtonComponent,
    NumberCellRendererComponent,
    StringCellEditorComponent,
    GridTooltipComponent,
    CheckboxCellEditorComponent,
    StringCountCellRendererComponent,
    EmptyCellRendererComponent,
    GridCheckedFlagComponent,
    OverlayComponent,
    DisabledCellRendererComponent,
    ProgressCellRendererComponent,
    TooltipCellRendererComponent,
    LinkIconRendererComponent,
    CustomCellEditorComponent,
    SuffixIconCellRendererComponent
  ],
  providers: [OverlayActionsService]
})
export class GridUtilsModule {}
